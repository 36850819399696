import { useAuth } from "@clerk/nextjs"
import { setAuthToken } from "@soar/frontend/trpc-client"
import { useAtom, useSetAtom } from "jotai"
import { useCallback, useEffect, useMemo, useState } from "react"

import { setUser as setSentryUser } from "@sentry/nextjs"
import { PreferencesContext, currentUserAtom } from "@soar/frontend/contexts"
import {
  DBUserStatus,
  OrgDetailsWithRole,
  UserContext,
  featureFlagsAtom,
  grabFromLocalStorage,
  loggedInAtom,
  organizationAtom,
  selectedLocationsAtom,
} from "@soar/frontend/contexts"
import { isTRPCClientError, trpc } from "@soar/frontend/trpc-client"
import {
  AircraftWithOrgDetailsSchema,
  FeatureFlagsPayload,
  PermissionName,
  RegisteredUser,
  RegisteredUserSchema,
  UserRole,
} from "@soar/shared/types"
import { ScopeDependencies, generatePermissions, hasActiveSubscription } from "@soar/shared/utils"
import { useRouter } from "next/router"
import { usePostHog } from "posthog-js/react"
import { useIntercom } from "react-use-intercom"

function MyAppInner({ children }) {
  const posthog = usePostHog()
  const { isLoaded, isSignedIn, getToken, signOut } = useAuth()
  const [enableUserLookup, setEnableUserLookup] = useState(false)
  const [orgLookupId, setOrgLookupId] = useState<string | undefined>()
  const [organization, setOrganization] = useAtom(organizationAtom)
  const [_locationIds, setLocationIds] = useAtom(selectedLocationsAtom)
  const { update: updateIntercom } = useIntercom()
  const setLoggedIn = useSetAtom(loggedInAtom)
  const setFeatureFlags = useSetAtom(featureFlagsAtom)

  const [user, setUser] = useAtom(currentUserAtom)
  const [status, setStatus] = useState<DBUserStatus>("initial")
  const isDbAuthenticated = user != null
  const router = useRouter()

  const onLogout = async () => {
    await signOut()
    setUser(null)
    setLoggedIn(false)
    setStatus("initial")
    setEnableUserLookup(false)
    router.push("/")
  }

  // Grab User for Context
  const { refetch: refetchUser } = trpc.user.getUser.useQuery(undefined, {
    enabled: enableUserLookup,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const parsedData = RegisteredUserSchema.parse(data)
      setUser(parsedData)
      setLoggedIn(true)
      setSentryUser({
        id: parsedData.id,
        email: parsedData.email,
        firstName: parsedData.firstName,
        lastName: parsedData.lastName,
      })
      posthog.identify(parsedData.id, {
        name: `${parsedData.firstName} ${parsedData.lastName}`,
        email: parsedData.email,
      })
      posthog.onFeatureFlags(() => {
        const featureFlags: FeatureFlagsPayload = {
          sms_enabled: posthog.isFeatureEnabled("feature_sms_enabled") ?? false,
        }
        setFeatureFlags(featureFlags)
      })
      updateIntercom({
        userId: parsedData.id,
        name: `${parsedData.firstName} ${parsedData.lastName}`,
        customAttributes: {
          stripeCustomerId: parsedData.stripeCustomerId,
        },
        email: parsedData.email,
      })
      setStatus("success")
      refetchAircraft()
    },
    onError: (error) => {
      const userNotFoundInApi = isTRPCClientError(error) && error.data?.code === "NOT_FOUND"
      setStatus(userNotFoundInApi ? "not_found" : "error")
    },
  })

  const {
    refetch: refetchOrgsAndLocations,
    data: orgLocData,
    isSuccess: isOrgDataLoaded,
  } = trpc.user.getUserOrganizationsAndLocations.useQuery(undefined, {
    enabled: enableUserLookup,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {},
    onError: (error) => {},
  })

  const {
    data: aircraftForUser,
    refetch: refetchAircraft,
    isLoading: isAircraftLoading,
    isSuccess: isAircraftSuccess,
  } = trpc.aircraft.getAircraftByUser.useQuery(undefined, {
    enabled: user != null,
    select: (dataFromDb) => {
      return dataFromDb.map((data) => AircraftWithOrgDetailsSchema.parse(data))
    },
    trpc: {
      context: {
        skipBatch: true,
      },
    },
  })

  const refetchAircraftAndParse = async () => {
    const refetchedAircraft = await refetchAircraft()
    const parsedAircraft = AircraftWithOrgDetailsSchema.array().safeParse(refetchedAircraft)

    return parsedAircraft.success ? parsedAircraft.data : []
  }

  // Grab Organization for Context
  trpc.organization.getOrganization.useQuery(
    { id: orgLookupId ?? "" },
    {
      enabled: orgLookupId != null && user != null,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setOrganization(data)
      },
    },
  )

  // Store Token for TRPC
  useEffect(() => {
    const storeToken = async () => {
      if (isSignedIn) {
        setAuthToken(getToken)
        setEnableUserLookup(true)
      }
    }
    storeToken()
  }, [isSignedIn, getToken])

  // Hydrate from localStorage
  useEffect(() => {
    if (user != null) {
      const storageContents = grabFromLocalStorage(user.id)
      const organizationId = storageContents?.organizationId ?? undefined
      const locationIds = storageContents?.locationIds

      setOrgLookupId(organizationId)
      if (locationIds != null) {
        setLocationIds(locationIds)
      }
    }
  }, [user])

  const refetch = () => {
    refetchUser()
    refetchOrgsAndLocations()
  }

  const hasPermission = useMemo(() => {
    const rolesForOrg = (orgLocData?.organizations
      .find((data) => data.organization.id === organization?.id)
      ?.roles.map((roleConfig) => roleConfig.role) ?? []) as UserRole[]
    if (user == null) {
      return () => false
    }
    return generatePermissions(user, rolesForOrg).hasPermission
  }, [organization, orgLocData, user])

  const hasPermissionForOrganization = useCallback(
    (organizationId: string, permission: PermissionName) => {
      const rolesForOrg = (orgLocData?.organizations
        .find((data) => data.organization.id === organizationId)
        ?.roles.map((roleConfig) => roleConfig.role) ?? []) as UserRole[]
      if (user == null) {
        return false
      }

      return generatePermissions(user, rolesForOrg).hasPermission(permission)
    },
    [orgLocData, user],
  )

  return (
    <UserContext.Provider
      value={{
        user: user ?? undefined,
        status,
        isLoaded: isLoaded,
        isSignedInToAuthService: isSignedIn,
        isAuthenticated: isDbAuthenticated,
        refetch,
        logout: onLogout,
        organizations: (orgLocData?.organizations as OrgDetailsWithRole[]) ?? [],
        locations: orgLocData?.locations ?? [],
        hasPermission: hasPermission,
        hasPermissionForOrganization: hasPermissionForOrganization,
        // TODO: Make a proper check for org subscription status
        hasActiveSubscription: hasActiveSubscription(user) || (orgLocData != null && orgLocData.organizations.length > 0),
        aircraft: aircraftForUser ?? [],
        refetchAircraft: refetchAircraftAndParse,
        isAircraftLoading: isAircraftLoading,
        isAircraftSuccess: isAircraftSuccess,
        isOrgDataLoaded,
      }}
    >
      <PreferencesContext.Provider
        value={{
          timeFormat: user?.timeFormatPreference ?? "format_12h",
        }}
      >
        {children}
      </PreferencesContext.Provider>
    </UserContext.Provider>
  )
}

export default MyAppInner
